<template>
  <Container dark>
    <label for="select-company">{{ $t("form.select_company_to_view") }}</label>
    <!-- <b-form-select
      v-if="isLoading"
      class="select"
      :class="!addCompany && 'mb-0'"
      :value="null"
      :options="[{ value: null, text: $t('common.loading') }]"
    ></b-form-select> -->
    <Loading v-if="isLoading" />
    <b-form-select
      id="select-company"
      :value="selectedCompanyId || null"
      :options="selectFromCompanies"
      @change="selectCompany($event)"
      class="select"
      :class="!addCompany && 'mb-0'"
      v-if="!isLoading && companies && companies.length"
    ></b-form-select>
    <CreateCompany v-if="addCompany && isAdmin" />
  </Container>
</template>
<script>
import Container from "../Container.vue";
import CreateCompany from "./CreateCompany.vue";
import Loading from "../Loading.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "select-company",
  components: {
    Container,
    CreateCompany,
    Loading,
  },
  props: {
    addCompany: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    selectCompany(e) {
      this.$store.dispatch("companies/selectCompany", e);
    },
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.isAdmin,
      selectedCompanyId: (state) => state.companies.selectedCompanyId,
      companies: (state) => state.companies.companies,
      isLoading: (state) => state.companies.isLoading,
    }),

    ...mapGetters("companies", ["selectFromCompanies"]),
  },
};
</script>
<style lang="scss" scoped>
label {
  font-weight: 500;
}
.select {
  max-width: 350px;
  display: block;
  margin-bottom: 2rem;
}
</style>
