<template>
  <div class="container-background" :class="dark ? 'dark' : ''">
    <div :class="!fluid ? 'container' : 'container-fluid'">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Container",
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.container-background {
  background-color: #fff;
  padding: 2rem 0;
  &.dark {
    background-color: #f2f5f7;
  }
}
</style>
