import { digitransit } from "./axiosConfig";

export const autocompleteAddress = async (search) => {
  try {
    const response = await digitransit.get(
      `geocoding/v1/autocomplete?text=${search}&layers=address`
    );
    return response.data.features;
  } catch {
    throw new Error(`Error getting addresses for ${search}`);
  }
};
