import { autocompleteAddress } from "@/api/addressAutocomplete";

export default {
  data() {
    return {
      autocompleteAddresses: [],
    };
  },
  methods: {
    async getAddressOptions(addressToSearch) {
      const response = await autocompleteAddress(addressToSearch);
      return response;
    },
    isValidAddress(addressToCheck) {
      return this.autocompleteAddresses.some(
        (address) =>
          address.properties.label === addressToCheck &&
          this.autocompleteAddresses.length
      )
        ? true
        : false;
    },
  },
  watch: {
    "data.address": async function (val) {
      if (!val) return (this.autocompleteAddresses = []);

      const res = await this.getAddressOptions(val);
      this.autocompleteAddresses = res;
    },
  },
};
