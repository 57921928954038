<template>
  <div
    class="header-container"
    :class="!fluid ? 'container' : 'container-fluid'"
  >
    <ul
      v-if="breadcrumbs && breadcrumbs.length > 0"
      class="list--nostyle breadcrumbs"
    >
      <li>Etusivu</li>
      <li>Yritystunnus</li>
    </ul>
    <div class="columns">
      <h2 class="heading" v-if="heading">{{ heading }}</h2>
      <slot name="header"></slot>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: {
    breadcrumbs: {
      type: Array,
      default: function () {
        return [];
      },
    },
    heading: String,
    fluid: Boolean,
  },
};
</script>
<style lang="scss">
@import "@/styles/style.scss";

.header-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    margin: 0;
  }

  ul.breadcrumbs {
    display: flex;
    margin-bottom: 1rem;
    font-weight: 500;

    li {
      &::after {
        content: ">";
      }
    }
  }

  .columns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem 1rem;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    .heading,
    & > *:first-child {
      flex: 1;
    }
  }
}
</style>
