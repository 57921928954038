<template>
  <div>
    <b-button v-b-modal.create-company class="button button--outline">{{
      $t("form.create_company.create_new_company")
    }}</b-button>
    <b-modal id="create-company" size="lg" hide-footer>
      <template #modal-header="{ close }">
        <button class="button button--nostyle ml-auto" @click="close()">
          <BIconX />
        </button>
      </template>
      <b-form class="create-company flex-form" @submit="submitForm" novalidate>
        <h4>{{ $t("form.create_company.title") }}</h4>
        <b-form-group
          id="company_name_group"
          label-for="company_name"
          :label="$t('form.create_company.company_name')"
          :state="!isDirty ? null : data.name.length > 0"
          :invalid-feedback="$t('common.fill_all_fields')"
        >
          <b-form-input
            id="company_name"
            name="company"
            v-model="data.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="contact_name_group"
          label-for="contact_name"
          :label="$t('form.create_company.contact_name')"
          :state="!isDirty ? null : data.contactPersonName.length > 0"
          :invalid-feedback="$t('common.fill_all_fields')"
        >
          <b-form-input
            id="contact_name"
            name="name"
            v-model="data.contactPersonName"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="contact_phone_group"
          label-for="contact_phone"
          :label="$t('form.create_company.contact_phone')"
          :state="!isDirty ? null : data.contactPersonPhone.length > 0"
          :invalid-feedback="$t('common.fill_all_fields')"
        >
          <b-form-input
            id="contact_phone"
            name="phone"
            v-model="data.contactPersonPhone"
            type="tel"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="company_address_group"
          label-for="company_hq_address"
          :label="$t('form.create_company.company_hq_address')"
          :state="!isDirty ? null : isValidAddress(data.address)"
          :invalid-feedback="$t('common.invalid_address')"
        >
          <b-form-input
            id="company_hq_address"
            name="address"
            list="company_hq_address_create"
            v-model="data.address"
            type="text"
            debounce="400"
            required
          ></b-form-input>
          <datalist id="company_hq_address_create">
            <option
              v-for="address in autocompleteAddresses"
              :key="address.properties.id"
            >
              {{ address.properties.label }}
            </option>
          </datalist>
        </b-form-group>
        <b-form-group
          id="company_id_group"
          label-for="company_id"
          :label="$t('form.create_company.company_id')"
          :state="!isDirty ? null : validCompanyId(data.ySign)"
          :invalid-feedback="$t('common.invalid_company_id')"
        >
          <b-form-input
            id="company_id"
            name="business_id"
            v-model="data.ySign"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="contact_title_group"
          label-for="contact_title"
          :label="$t('form.create_company.contact_title')"
          :state="!isDirty ? null : data.contactPersonTitle.length > 0"
          :invalid-feedback="$t('common.fill_all_fields')"
        >
          <b-form-input
            id="contact_title"
            name="job_title"
            v-model="data.contactPersonTitle"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="contact_email_group"
          label-for="contact_email"
          :label="$t('form.create_company.contact_email')"
          :state="!isDirty ? null : validEmail(data.contactPersonEmail)"
          :invalid-feedback="$t('common.invalid_email')"
        >
          <b-form-input
            id="contact_email"
            name="email"
            v-model="data.contactPersonEmail"
            type="email"
            required
          ></b-form-input>
        </b-form-group>
        <!-- <b-alert show variant="danger" v-if="errors && errors.length">
          <ul class="list--nostyle">
            <li v-for="error in errors" :key="error">
              {{ error }}
            </li>
          </ul>
        </b-alert> -->
        <button
          type="submit"
          class="button button--filled-blue"
          @submit="submitForm"
          :disabled="isSubmitting"
        >
          <div v-if="isSubmitting">
            <b-spinner small class="mr-2"></b-spinner>
            <span>{{ $t("common.submitting") }}...</span>
          </div>
          <span v-else>
            {{ $t("form.save_and_close") }}
          </span>
        </button>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { BIconX } from "bootstrap-vue";
import formValidation from "@/mixins/formValidation";
import { createOrganization } from "@/api/organizationController";
import addressAutocompleteValidation from "@/mixins/addressAutocompleteValidation";

const initData = {
  name: "",
  contactPersonName: "",
  contactPersonPhone: "",
  address: "",
  ySign: "",
  contactPersonTitle: "",
  contactPersonEmail: "",
  referencingAllowed: false,
};

export default {
  name: "create-company",
  mixins: [formValidation, addressAutocompleteValidation],
  components: {
    BIconX,
  },
  data() {
    return {
      data: { ...initData },
      isSubmitting: false,
      errors: [],
      isDirty: false,
    };
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      this.errors = [];
      this.isDirty = true;
      if (
        this.validForm(this.data) &&
        this.validEmail(this.data.contactPersonEmail) &&
        this.validCompanyId(this.data.ySign) &&
        this.isValidAddress(this.data.address)
      ) {
        createOrganization(this.data).then((res) => {
          this.isSubmitting = true;
          if (res.status === 200) {
            this.$bvToast.toast(this.$t("toast.created_successfully"), {
              toaster: "b-toaster-bottom-right",
              variant: "success",
              noCloseButton: true,
            });
            this.$store.dispatch("companies/getCompanies");
            this.data = { ...initData };
            this.isDirty = false;
            this.isSubmitting = false;
            return this.$bvModal.hide("create-company");
          }
          this.$bvToast.toast(this.$t("toast.something_went_wrong"), {
            toaster: "b-toaster-bottom-right",
            variant: "danger",
            noCloseButton: true,
          });
          this.isDirty = false;
          this.isSubmitting = false;
        });
      }

      if (!this.validEmail(this.data.contactPersonEmail)) {
        this.errors.push(this.$t("common.invalid_email"));
      }
      if (!this.validCompanyId(this.data.ySign)) {
        this.errors.push(this.$t("common.invalid_company_id"));
      }
      if (!this.validForm(this.data)) {
        this.errors.push(this.$t("common.fill_all_fields"));
      }
      if (!this.isValidAddress(this.data.address)) {
        this.errors.push(this.$t("common.invalid_address"));
      }
    },
    validEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // Another one
      // /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;\s@"]+\.{0,1})+[^<>().,;:\s@"]{2,})$/;
      return re.test(String(email).toLowerCase());
    },
    validCompanyId(id) {
      const re = /^[0-9]{7}-[0-9]{1}$/;
      return re.test(String(id));
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/HSLColors.scss";

.create-company {
  padding: 0 2rem 2rem;

  h4 {
    font-weight: normal;
    margin-bottom: 2rem;
  }

  label {
    font-weight: 500;
    margin-bottom: 0;
    &::after {
      content: "*";
      color: $color-hsl-huomiopunainen;
      margin-left: 0.25em;
    }
  }

  input {
    max-width: 540px;
  }

  input#company_id,
  input#contact_phone {
    max-width: 200px;
  }
}
</style>
