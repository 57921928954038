export default {
  data() {
    return {
      valid: false,
      validated: false,
      error: "",
    };
  },
  methods: {
    validForm(data) {
      this.valid = false;
      this.error = "";
      this.validated = true;

      if (
        !Object.values(data).every(
          (item) => item || typeof item === "boolean" || item === 0
        )
      ) {
        this.error = this.$t("common.fill_all_fields");
        return false;
      }

      this.validated = false;
      return (this.valid = true);
    },
  },
};
